import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex justify-between bg-gray-50 rounded-md shadow mb-5"
};
const _hoisted_2 = {
  key: 1,
  class: "flex items-center mb-5"
};
const _hoisted_3 = {
  class: "text-[25px] text-gray-600 font-light leading-20"
};
const _hoisted_4 = {
  key: 0,
  class: "text-[17px] text-gray-600 font-medium ml-[5px] leading-20 mt-[4px]"
};
const _hoisted_5 = {
  class: "grid grid-cols-4 gap-x-2 gap-y-5"
};
import { ref, computed } from "vue";
import { useStore } from "vuex";
// define props

export default {
  __name: 'ProductSection',
  props: {
    title: {
      type: String
    },
    number: {
      type: Number
    },
    showCounter: {
      type: Boolean,
      default: false
    },
    filterView: {
      type: Boolean,
      default: false
    },
    products: {
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const store = useStore();
    const loggedIn = computed(() => {
      return store.state.user.loggedIn;
    });

    // use computed state to store the passed props from parents due to ref & reactive saves the value
    // but we want to refresh it each time the user navigates to another page
    const currentTitle = computed(() => {
      return props.title;
    });

    // q = search
    const q = ref("");

    // s = sorting
    const s = ref("");

    // creating computed for all products
    const products = computed(() => {
      let products = props.products;

      //setting values up
      const query = q.value.toLowerCase();
      const sortBy = s.value.toLowerCase();

      // if query has something
      if (query) {
        products = products.filter(item => item.name.toLowerCase().includes(query));
      }

      // switch case for user sorting 
      switch (sortBy) {
        case "none":
          products = products.sort((a, b) => {
            let fa = a.id,
              fb = b.id;
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          break;
        case "price_low":
          products = products.sort((a, b) => {
            let fa = a.price,
              fb = b.price;
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          break;
        case "price_high":
          products = products.sort((b, a) => {
            let fa = a.price,
              fb = b.price;
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          break;
      }
      return products;
    });
    return (_ctx, _cache) => {
      const _component_FilterView = _resolveComponent("FilterView");
      const _component_ProductCard = _resolveComponent("ProductCard");
      return _openBlock(), _createElementBlock("div", null, [__props.filterView ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_FilterView, {
        onSearchProduct: _cache[0] || (_cache[0] = query => q.value = query),
        onSortBy: _cache[1] || (_cache[1] = sortBy => s.value = sortBy),
        title: __props.title,
        showCounter: __props.showCounter,
        number: __props.number
      }, null, 8, ["title", "showCounter", "number"])])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("h2", _hoisted_3, _toDisplayString(_unref(currentTitle)), 1), __props.showCounter ? (_openBlock(), _createElementBlock("h3", _hoisted_4, "- " + _toDisplayString(__props.number) + " ialt", 1)) : _createCommentVNode("", true)])), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(products), product => {
        return _openBlock(), _createElementBlock("div", {
          class: "col-span-1",
          ref_for: true,
          ref: "product_card",
          key: product.id
        }, [_createVNode(_component_ProductCard, {
          product: product,
          loggedIn: _unref(loggedIn)
        }, null, 8, ["product", "loggedIn"])]);
      }), 128))])]);
    };
  }
};