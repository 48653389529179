import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { cart } from "@/store/modules/cart";
export default {
  __name: 'AddToCartButton',
  props: {
    product: {
      type: Object
    },
    number: {
      type: Number,
      default: 1
    }
  },
  setup(__props) {
    const props = __props;
    const addToCart = () => {
      cart.addProduct(props.product, props.number);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("button", {
        onClick: _cache[0] || (_cache[0] = $event => addToCart()),
        class: "bg-primary w-full py-2 rounded text-white mt-3 hover:opacity-75 hover-transition"
      }, "Tilføj til kurv");
    };
  }
};