import { createStore } from 'vuex'

export default {
    state: {
        user: {},
        loggedIn: false,
        favorites:{}
    },
    getters: {
    },
    mutations: {
        CHANGE_TO_LOGIN: (state: any, value: any) => {
            state.user = value,
            state.loggedIn = true
        },
        CHANGE_TO_FAVORITES: (state: any, value: any) => {
            state.favorites = value
        },
        CHANGE_TO_LOGOUT: (state: any, value: any) => {
            state.user = {}
            state.favorites = {}
            state.loggedIn = false
        }
    },
    actions: {
        changeUserLogin({ commit }: { commit: Function }, payload: any) {
            commit("CHANGE_TO_LOGIN", payload);
        },
        changeUserFavorites({ commit }: { commit: Function }, payload: any) {
            commit("CHANGE_TO_FAVORITES", payload);
        },
        changeUserLogout({ commit }: { commit: Function }, payload: any) {
            commit("CHANGE_TO_LOGOUT", payload);
        }
    },
}
