import { createStore } from 'vuex'
import config from './modules/config';
import modal from './modules/modal'
import user from './modules/user'
import createPersistedState from "vuex-persistedstate";

export default createStore({
    modules: {
        modal,
        user,
        config
    },
    plugins:[createPersistedState()]
})