import { createStore } from 'vuex'

export default {
    state: {
        AuthModal: false
    },
    getters: {
    },
    mutations: {
        CHANGE_AUTH_STATE: (state: any, value: boolean) => {
            state.AuthModal = value
        }
    },
    actions: {
        changeAuthModal({ commit }: { commit: Function }, payload: boolean) {
            commit("CHANGE_AUTH_STATE", payload);
        }
    },
    modules: {
    }
}
