import { createStore } from 'vuex'

export default {
   state: {
      settings: {}
   },
   getters: {
   },
   mutations: {
      CHANGE_SETTINGS: (state: any, value: any) => {
         state.settings = value
      },
   },
   actions: {
      changeSettings({ commit }: { commit: Function }, payload: any) {
         commit("CHANGE_SETTINGS", payload);
      },
   },
}
