import { reactive, ref } from 'vue'

export const cart = reactive({
  // If there is localStorage, set them as value or empty array
  products: ref(JSON.parse(localStorage.getItem('cart')!) !== null ? JSON.parse(localStorage.getItem('cart')!) : []),

  addProduct(product: any, number: number) {
    // Determine whatever the product is already on the cart
    const isNewProduct = ref(true);

    // Create the new product with number
    if (!product.number) {
      product.number = number;
    }

    // Check if the product already exists on the cart
    this.products.map(function(value: any, index: number) {
      if (value.id === product.id) {
        value.number += number;
        isNewProduct.value = false;
      }
    });

    // If the products does not exists, push it to the storage
    if (isNewProduct.value === true) {
      this.products.push(product);
      localStorage.setItem('cart', JSON.stringify(this.products));
    } else {
      this.updateLocal();
    }
  },
  updateOnExistsProduct(product: any, number: number) {
    this.products.map(function(value: any) {
      if (value.id === product.id) {
        value.number = number;
      }
    });
    this.updateLocal();
  },
  addOnExistsProduct(product: any, number: number) {
    this.products.map(function(value: any) {
      if (value.id === product.id) {
        value.number = value.number + number;
      }
    });
    this.updateLocal();
  },
  minusOnExistsProduct(product: any, number: number) {
    this.products.map(function(value: any) {
      if (value.id === product.id) {
        value.number = value.number - number;
      }
    });
    this.updateLocal();
  },
  getProducts() {
    return JSON.parse(localStorage.getItem('cart')!);
  },
  updateLocal() {
    localStorage.removeItem('cart');
    localStorage.setItem('cart', JSON.stringify(this.products));
  },
  removeProduct(product: any) {
    let index = this.products.map((item : any) => item.product_number).indexOf(product.product_number);
    this.products.splice(index, 1);
    this.updateLocal();
  },
  emptyProducts() {
    this.products = [];
    localStorage.removeItem('cart');
  }
})