import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import CategoryView from '../views/CategoryView.vue'
import NotFoundView from '../views/404View.vue'

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/kategori/:id', name: 'kategori', component: CategoryView },
  { path: '/produkt/:id', component: () => import('../views/ProductView.vue') },
  { path: '/cart', component: () => import('../views/CartView.vue') },
  { path: '/search', component: () => import('../views/SearchView.vue') },
  { path: '/profil', component: () => import('../views/ProfileView.vue') },
  { path: '/favoritter', component: () => import('../views/Favorites.vue') },
  { path: '/betingelser', component: () => import('../views/TermsView.vue') },
  { path: '/receipt/:id', component: () => import('../views/ReceiptView.vue') },
  // Not found 404
  { path: '/:pathMatch(.*)*', component: NotFoundView },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
